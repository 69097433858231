import React from 'react'
import { MiniChart, CryptoCurrencyMarket } from 'react-ts-tradingview-widgets'

const Markets = () => {
  return (
    <section
      id="content-3" style={{backgroundColor: "#052a24", marginTop: '0px'}}
      className="content-3 content-section "
    >
      <div className="container-fluid">
      <h2 className="h2-xs color-white white-color text-white text-center pt-4">Cryptocurrency Markets</h2>
      
        <div className="row gx-2">
            <div className='col-md-3 col-6'>
                <div className='p-2 my-4 bg-transparent' style={{borderRadius:"12px",boxShadow: '#be7f48 0px 1px 5px 0px', height: "235px", overflow: 'hidden'}}>
                    <MiniChart symbol= "BINANCE:BTCUSDT"
                        height= {220}
                        locale= "en"
                        dateRange= "12M"
                        colorTheme= "dark"
                        trendLineColor= "rgba(6, 222, 156, 1)"
                        underLineColor= "rgba(136, 241, 195, 0.16)"
                        underLineBottomColor= "rgba(41, 98, 255, 0)"
                        isTransparent= {true}
                        autosize= {false}
                        width="100%"/>
                </div>
            </div>

            <div className='col-md-3 col-6'>
                <div className='p-2 my-4 bg-transparent' style={{borderRadius:"12px",boxShadow: '#be7f48 0px 1px 5px 0px', height: "235px", overflow: 'hidden'}}>
                    <MiniChart symbol= "BINANCE:ETHUSDT"
                        height= {220}
                        locale= "en"
                        dateRange= "12M"
                        colorTheme= "dark"
                        trendLineColor= "rgba(6, 222, 156, 1)"
                        underLineColor= "rgba(136, 241, 195, 0.16)"
                        underLineBottomColor= "rgba(41, 98, 255, 0)"
                        isTransparent= {true}
                        autosize= {false}
                        width="100%"/>
                </div>
            </div>

            <div className='col-md-3 col-6'>
                <div className='p-2 my-4 bg-transparent' style={{borderRadius:"12px",boxShadow: '#be7f48 0px 1px 5px 0px', height: "235px", overflow: 'hidden'}}>
                    <MiniChart symbol= "BINANCE:DOGEUSDT"
                        height= {220}
                        locale= "en"
                        dateRange= "12M"
                        colorTheme= "dark"
                        trendLineColor= "rgba(6, 222, 156, 1)"
                        underLineColor= "rgba(136, 241, 195, 0.16)"
                        underLineBottomColor= "rgba(41, 98, 255, 0)"
                        isTransparent= {true}
                        autosize= {false}
                        width="100%"/>
                </div>
            </div>

            <div className='col-md-3 col-6'>
                <div className='p-2 my-4 bg-transparent' style={{borderRadius:"12px", boxShadow: '#be7f48 0px 1px 5px 0px',  height: "235px", overflow: 'hidden'}}>
                    <MiniChart symbol= "BINANCE:LINKUSDT"
                        height= {220}
                        locale= "en"
                        dateRange= "12M"
                        colorTheme= "dark"
                        trendLineColor= "rgba(6, 222, 156, 1)"
                        underLineColor= "rgba(136, 241, 195, 0.16)"
                        underLineBottomColor= "rgba(41, 98, 255, 0)"
                        isTransparent= {true}
                        autosize= {false}
                        width="100%"/>
                </div>
            </div>


            <div className='col-md-12'>
                <div className='p-2 my-4 bg-transparent' style={{borderRadius:"12px", boxShadow: '#be7f48 0px 1px 5px 0px',  height: "455px", overflow: 'hidden'}}>
                <CryptoCurrencyMarket 
                width= "100%"
                defaultColumn= "overview"
                screener_type= "crypto_mkt"
                displayCurrency= "USD"
                colorTheme= "dark"
                locale= "en"
                isTransparent= {true}
                />
                </div>
            </div>
        </div>
      </div>
    </section>
  )
}

export default Markets