import React from 'react'

const Contact = () => {
  return (
    <div>
  <section className="inner-page-banner-section gradient-bg">
    <div className="illustration-img"><img src="assets/images/inner-page-banner-illustrations/contact.png" alt="image-illustration" /></div>
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="inner-page-content-area">
            <h2 className="page-title">Contact Us</h2>
            <nav aria-label="breadcrumb" className="page-header-breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="home-one.html">Home</a></li>
                <li className="breadcrumb-item">Contact</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* inner-page-banner-section end */}
  <section className="contact-section mt-minus pb-120">
    <div className="container">
      <div className="contact-form-area">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="section-header text-center">
              <span className="section-subtitle">Contact</span>
              <h2 className="section-title">Get In Touch</h2>
              <p>Have questions? We don't bite! Just send a mail to <a href="mailto: support@jetpayglobal.com">support@jetpayglobal.com</a> and we'll get back to you as soon as possible!
              </p>
            </div>
          </div>
        </div>
        <div className="social-links-area pt-120"> 
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="section-header text-center">
                <span className="section-subtitle">Join The Conversation!</span>
                <h2 className="section-title">Browse Our Social Pages</h2>
              </div>
            </div>
          </div>
          <div className="row mb-none-30"> 
            <div className="col-lg-3 col-sm-6">
              <div className="social-media-item text-center mb-30">
                <div className="icon"> 
                  <i className="fa fa-facebook-f" />
                </div>
                <div className="content">
                  <h3 className="counter">32,334</h3>
                  <p>facebook members</p>
                  <a href="https://account.jetpayglobal.com/" className="join-btn">join!</a>
                </div>
              </div>
            </div>{/* social-media-item end*/}
            <div className="col-lg-3 col-sm-6">
              <div className="social-media-item text-center mb-30">
                <div className="icon"> 
                  <i className="fa fa-twitter" />
                </div>
                <div className="content">
                  <h3 className="counter">32,334</h3>
                  <p>Twitter followers</p>
                  <a href="https://account.jetpayglobal.com/" className="join-btn">join!</a>
                </div>
              </div>
            </div>{/* social-media-item end*/}
            <div className="col-lg-3 col-sm-6">
              <div className="social-media-item text-center mb-30">
                <div className="icon"> 
                  <i className="fa fa-instagram" />
                </div>
                <div className="content">
                  <h3 className="counter">20,150</h3>
                  <p>Instagram subscribers</p>
                  <a href="https://account.jetpayglobal.com/" className="join-btn">join!</a>
                </div>
              </div>
            </div>{/* social-media-item end*/}
            <div className="col-lg-3 col-sm-6">
              <div className="social-media-item text-center mb-30">
                <div className="icon"> 
                  <i className="fa fa-paper-plane-o" />
                </div>
                <div className="content">
                  <h3 className="counter">30,334</h3>
                  <p>Telegram members</p>
                  <a href="https://account.jetpayglobal.com/" className="join-btn">join!</a>
                </div>
              </div>
            </div>{/* social-media-item end*/}
          </div>
        </div>
      </div>
    </div></section>
</div>

  )
}

export default Contact
