import React from 'react'
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header className="header-section">
  <div className="header-bottom" style={{backgroundColor: '#000000'}}>
    <div className="container" style={{backgroundColor: '#000000'}}>
      <nav className="navbar navbar-expand-xl" >
        <a className="site-logo site-title" href="/"><img style={{height: '40px'}} src="https://res.cloudinary.com/dvoynhghq/image/upload/v1715753078/jetpaydouble-3dwhite_nrartk.png" alt="site-logo" /></a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="menu-toggle" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav main-menu ml-auto mr-4">
            <li><Link style={{color: '#fff'}} to="/about">About</Link></li>
            <li><Link style={{color: '#fff'}} to="/real-estate">Real Estate</Link></li>
            <li><Link style={{color: '#fff'}} to="/sports">Sports</Link></li>
            <li><Link style={{color: '#fff'}} to="/gold-mine">Gold Mine</Link></li>
            <li><Link style={{color: '#fff'}} to="/affiliate">Affiliate</Link></li>
            <li><Link style={{color: '#fff'}} to="/packages">Packages</Link></li>              
            <li><Link style={{color: '#fff'}} to="/contact">contact</Link></li>
          </ul>
          <div className="header-join-part">
            <a type="button" className="btn btn-primary btn-hover" href="https://account.jetpayglobal.com">join us</a>
          </div>
        </div>
      </nav>
    </div>
  </div>{/* header-bottom end */}
</header>

  )
}

export default Header
