import React from 'react'

const Testimonial = () => {
  return (
    <section className="testimonial-section pt-xl-120 pb-120" style={{backgroundColor: '#07231b'}}>
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-lg-10">
        <div className="section-header text-center">
          <span className="section- text-white">Testimonials</span>
          <h2 className="section-title text-white">Over 7,000 Happy Customers</h2>
          <p style={{color: '#eaf3f1'}}>We have many happy investors invest with us .Some impresions from our Customers!  PLease read some of the lovely things our Customers say about us.</p>
          <div className="btn-area">
            <a href="https://dashboard.jetpayglobal.com" className="btn btn-primary text-small">join us</a>
            <a href="https://dashboard.jetpayglobal.com" className="btn btn-primary text-small">what we offer</a>
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-12">
        <div className="testimonial-wrapper style--one d-lg-flex flex-wrap justify-content-between d-none">
          <div className="testimonial-single">
            <div className="thumb">
              <img src="assets/images/testimonial/1.png" alt="image" />
            </div>
            <div className="details text-center">
              <p>Great service! I have been worried about investing. But when I came here. I don't have to worry anymore</p>
              <h4 className="name"> Alice S. Runner</h4>
              <span className="client-details">United kingdom, 28th April,2019</span>
              <span className="arrow-wrap"><span className="arrow" /></span>
            </div>
          </div>{/* testimonial-single end */}
          <div className="testimonial-single">
            <div className="thumb">
              <img src="assets/images/testimonial/2.png" alt="image" />
            </div>
            <div className="details text-center">
              <p>I was sceptical about joining them at first. I decided to start up with something I knew I could forgo. I did this many times and they never faltered. This was some years ago. Now I know my money is guaranteed with them.</p>
              <h4 className="name">Scott B. Boatman</h4>
              <span className="client-details">United kingdom, 28th April,2019</span>
              <span className="arrow-wrap"><span className="arrow" /></span>
            </div>
          </div>{/* testimonial-single end */}
          <div className="testimonial-single">
            <div className="thumb">
              <img src="assets/images/testimonial/3.png" alt="image" />
            </div>
            <div className="details text-center">
              <p>I have had the opportunity to witness the inner workings of project invest as an investment expert. I know there are great companies out there, but I doubt I would ever find any one as good.</p>
              <h4 className="name">Pennie A. Cousins</h4>
              <span className="client-details">USA, 28th May,2020</span>
              <span className="arrow-wrap"><span className="arrow" /></span>
            </div>
          </div>{/* testimonial-single end */}
          <div className="testimonial-single active">
            <div className="thumb">
              <img src="assets/images/testimonial/4.png" alt="image" />
            </div>
            <div className="details text-center">
              <p>Service and information was prompt and honest. Usually one has to make certain commitments before getting a full report and breakdown sheet with recommendations on investment advice. Would recommend to include 'JETPAY GLOBAL' for anyone looking for investment advice.</p>
              <h4 className="name">Diane W. Molina</h4>
              <span className="client-details">France, 20th June,2019</span>
              <span className="arrow-wrap"><span className="arrow" /></span>
            </div>
          </div>{/* testimonial-single end */}
          <div className="testimonial-single">
            <div className="thumb">
              <img src="assets/images/testimonial/5.png" alt="image" />
            </div>
            <div className="details text-center">
              <p>From the time I first connected with JETPAY GLOBAL many years ago, the feel-good element was present. Professional, friendly, and proactive advice and suggestions, have always been provided in the best interests of the client. I continue to be very happy with all interactions with JETPAY GLOBAL, from my designated Financial Planner, to whoever else might be on the end of a telephone call or e-mail.</p>
              <h4 className="name">John M. Smith</h4>
              <span className="client-details">United kingdom, 2rd December,2021</span>
              <span className="arrow-wrap"><span className="arrow" /></span>
            </div>
          </div>{/* testimonial-single end */}
          <div className="testimonial-single">
            <div className="thumb">
              <img src="assets/images/testimonial/6.png" alt="image" />
            </div>
            <div className="details text-center">
              <p>Super impressed by the amazing service and friendly team of professionals!</p>
              <h4 className="name">Alva S. Diaz</h4>
              <span className="client-details">Canada, 25th September,2021</span>
              <span className="arrow-wrap"><span className="arrow" /></span>
            </div>
          </div>{/* testimonial-single end */}
        </div>
        <div className="testmonial-slider-wrapper d-lg-none mb-4">
          <div className="testimonial-slider owl-carousel">
            <div className="testimonial-single-two">
              <div className="thumb"><img src="assets/images/testimonial/2.png" alt="image" /></div>
              <h4 className="name">Charlene T. Ross</h4>
              <span className="designation">Award winning blogger</span>
              <p>I would recommend JETPAY GLOBAL to everyone as I feel so comfortable knowing I invested my money with them!!

</p>
            </div>{/* testimonial-single-two end */}
            <div className="testimonial-single-two">
              <div className="thumb"><img src="assets/images/testimonial/3.png" alt="image" /></div>
              <h4 className="name">Sam K. Johnson</h4>
              <span className="designation">Award winning blogger</span>
              <p>This company’s excellent administration and sound guidance in navigating financial investments makes them a top choice.

</p>
            </div>{/* testimonial-single-two end */}
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


  )
}

export default Testimonial
