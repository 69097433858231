import React from 'react'

const About = () => {
  return (
    <div>
  <section className="inner-page-banner-section gradient-bg">
    <div className="illustration-img"><img src="assets/images/inner-page-banner-illustrations/about.png" alt="image-illustration" /></div>
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="inner-page-content-area">
            <h2 className="page-title">about us</h2>
            <nav aria-label="breadcrumb" className="page-header-breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="/">Home</a></li>
                <li className="breadcrumb-item">result</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* inner-page-banner-section end */}
  {/* about-section start */}
  <section className="about-section start pt-120 pb-120">
    <div className="shape-el">
      <img src="assets/images/elements/about.png" alt="image" />
    </div>
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="row align-items-center">
            <div className="col-lg-6 col-sm-6 mb-none-30">
              <div className="counter-item style--two mb-30">
                <div className="counter-icon">
                  <img src="assets/images/icons/about/1.svg" alt="icon" />
                </div>
                <div className="counter-content pl-0">
                  <span className="counter">634</span>
                  <span>k</span>
                  <span className="caption">Registered Members </span>
                </div>
              </div>{/* counter-item end*/}
              <div className="counter-item style--two mb-30">
                <div className="counter-icon">
                  <img src="assets/images/icons/about/2.svg" alt="icon" />
                </div>
                <div className="counter-content pl-0">
                  <span className="counter">08</span>
                  <span>M</span>
                  <span className="caption">Satisfied customers</span>
                </div>
              </div>{/* counter-item end*/}
              <div className="counter-item style--two mb-30">
                <div className="counter-icon">
                  <img src="assets/images/icons/about/3.svg" alt="icon" />
                </div>
                <div className="counter-content pl-0">
                  <span className="counter">10</span>
                  <span>B</span>
                  <span className="caption">Satisfied customers</span>
                </div>
              </div>{/* counter-item end*/}
            </div>
            <div className="col-lg-6 col-sm-6 mb-none-30 mt-sm-0 mt-5">
              <div className="counter-item style--two mb-30">
                <div className="counter-icon">
                  <img src="assets/images/icons/about/4.svg" alt="icon" />
                </div>
                <div className="counter-content pl-0">
                  <span>$</span>
                  <span className="counter">14</span>
                  <span>k</span>
                  <span className="caption">Average Investment</span>
                </div>
              </div>{/* counter-item end*/}
              <div className="counter-item style--two mb-30">
                <div className="counter-icon">
                  <img src="assets/images/icons/about/5.svg" alt="icon" />
                </div>
                <div className="counter-content pl-0">
                  <span>$</span>
                  <span className="counter">20</span>
                  <span>k</span>
                  <span className="caption">Financial Products</span>
                </div>
              </div>{/* counter-item end*/}
            </div>
          </div>
        </div>
        <div className="col-lg-6 mt-lg-0 mt-5">
          <div className="about-content">
            <div className="section-header">
              <span className="section-subtitle">Earn The Big Money</span>
              <h2 className="section-title">Affiliate Program</h2>
              <p>To meet <a href="https://account.jetpayglobal.com">today's challenges</a>, we've created a unique fund management system</p>
            </div>
            <p>JETPAY GLOBAL is a global online investment platform that allows qualifying investors to invest in financial innovation including Real Estate, Cryptocurrency, Forex and Sports and other new alternative financial products.</p>
            <p>We believe that the future of finance looks very different to how it looks today and we offer qualifying investors the opportunity to invest in the companies and products that are building the future of finance.</p>
            <a href="https://account.jetpayglobal.com" className="btn btn-primary btn-hover text-small">get started</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* about-section end */}
  {/* global-section start */}
  <section className="global-section">
    <div className="container-fluid">
      <div className="row justify-content-end">
        <div className="col-xl-4 order-xl-1 order-2">
          <div className="global-content">
            <div className="section-header">
              <span className="section-subtitle">Take A Look</span>
              <h2 className="section-title">Global Presence</h2>
            </div>
            <p>We are 100% funded from our own platform with a team of over 300 professional FinTech investors who all believe the future of finance looks very different from today. Their experience and background covers most areas in finance, technology and startups.66,246 professional investors are waiting to invest.</p>
            <p>We have built the largest global community of professional FinTech investors all investing online in the future of finance in over 50 currencies.</p>
            <a href="https://account.jetpayglobal.com" className="btn btn-primary btn-hover">get started</a>
          </div>
        </div>
        <div className="col-xl-6 order-xl-2 order-1 map-bg">
          <img src="assets/images/elements/map.png" alt="image" />
        </div>
      </div>
    </div>
  </section>
  {/* global-section end */}
  {/* choose-us-section start */}
  <section className="choose-us-section pt-120 pb-120">
    <div className="animated-round" />
    <div className="container-fluid">
      <div className="row justify-content-xl-start justify-content-lg-center">
        <div className="col-xl-5 offset-xl-5 col-lg-6">
          <div className="section-header text-xl-left text-center">
            <span className="section-subtitle">A Few Reasons Why</span>
            <h2 className="section-title">Investors Choose Us</h2>
            <p>Our service gives you better result and savings, as per your requirement and you can manage your investments from anywhere either form home or work place, at any time.</p>
          </div>
        </div>
      </div>
      <div className="row align-content-center">
        <div className="col-xl-5">
          <div className="video-area">
            <img src="assets/images/elements/makbook.png" alt="image" />
            <a href="https://account.jetpayglobal.com" className="round-video-btn"><i className="fa fa-play" /></a>
          </div>
        </div>
        <div className="col-xl-7 mt-xl-0 mt-5">
          <div className="choose-us-slider__four owl-carousel">
            <div className="choose-item style--two text-center">
              <div className="choose-icon">
                <img src="assets/images/icons/choose-us/1.svg" alt="image" />
              </div>
              <div className="choose-content">
                <h3 className="title">Fast Profit </h3>
                <p>We're talking about ways you can make money fast. Invest money and get reward, bonus and profit</p>
              </div>
            </div>{/* choose-item end */}
            <div className="choose-item style--two text-center">
              <div className="choose-icon">
                <img src="assets/images/icons/choose-us/1.svg" alt="image" />
              </div>
              <div className="choose-content">
                <h3 className="title">Fast Profit </h3>
                <p>We're talking about ways you can make money fast.Invest money and get reward, bonus and profit</p>
              </div>
            </div>{/* choose-item end */}
            <div className="choose-item style--two text-center">
              <div className="choose-icon">
                <img src="assets/images/icons/choose-us/1.svg" alt="image" />
              </div>
              <div className="choose-content">
                <h3 className="title">Fast Profit </h3>
                <p>We're talking about ways you can make money fast.Invest money and get reward, bonus and profit</p>
              </div>
            </div>{/* choose-item end */}
            <div className="choose-item style--two text-center">
              <div className="choose-icon">
                <img src="assets/images/icons/choose-us/1.svg" alt="image" />
              </div>
              <div className="choose-content">
                <h3 className="title">Fast Profit </h3>
                <p>We're talking about ways you can make money fast.Invest money and get reward, bonus and profit</p>
              </div>
            </div>{/* choose-item end */}
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* choose-us-section end */}
  {/* team-section start */}
  <section className="team-section gradient-overlay bg_img pt-120" data-background="assets/images/team-section-bg.jpg">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <div className="section-header text-center text-white">
            <span className="section-subtitle">Meet our Creative</span>
            <h2 className="section-title">Management Team</h2>
            <p>Our team of creative programmers, marketing experts, and members of the global investment community have worked together to build the ultimate investment site, happy Investor reminds us how lucky .we are to be doing what we love.</p>
          </div>
        </div>
      </div>
      <div className="team-slider owl-carousel">
        <div className="team-item">
          <div className="thumb">
            <img src="assets/images/team/1.png" alt="image" />
          </div>
          <div className="content text-center">
            <h4 className="name">al burns</h4>
            <span className="designation">ceo</span>
            <ul className="socials-links">
              <li><a href="https://account.jetpayglobal.com"><i className="fa fa-facebook-f" /></a></li>
              <li><a href="https://account.jetpayglobal.com"><i className="fa fa-twitter" /></a></li>
              <li><a href="https://account.jetpayglobal.com"><i className="fa fa-google-plus" /></a></li>
              <li><a href="https://account.jetpayglobal.com"><i className="fa fa-instagram" /></a></li>
            </ul>
          </div>
        </div>{/* team-item end */}
        <div className="team-item">
          <div className="thumb">
            <img src="assets/images/team/2.png" alt="image" />
          </div>
          <div className="content text-center">
            <h4 className="name">Rosie Holt </h4>
            <span className="designation">Advisory Member</span>
            <ul className="socials-links">
              <li><a href="https://account.jetpayglobal.com"><i className="fa fa-facebook-f" /></a></li>
              <li><a href="https://account.jetpayglobal.com"><i className="fa fa-twitter" /></a></li>
              <li><a href="https://account.jetpayglobal.com"><i className="fa fa-google-plus" /></a></li>
              <li><a href="https://account.jetpayglobal.com"><i className="fa fa-instagram" /></a></li>
            </ul>
          </div>
        </div>{/* team-item end */}
        <div className="team-item">
          <div className="thumb">
            <img src="assets/images/team/3.png" alt="image" />
          </div>
          <div className="content text-center">
            <h4 className="name">Tony Welch</h4>
            <span className="designation">IT Specialist</span>
            <ul className="socials-links">
              <li><a href="https://account.jetpayglobal.com"><i className="fa fa-facebook-f" /></a></li>
              <li><a href="https://account.jetpayglobal.com"><i className="fa fa-twitter" /></a></li>
              <li><a href="https://account.jetpayglobal.com"><i className="fa fa-google-plus" /></a></li>
              <li><a href="https://account.jetpayglobal.com"><i className="fa fa-instagram" /></a></li>
            </ul>
          </div>
        </div>{/* team-item end */}
        <div className="team-item">
          <div className="thumb">
            <img src="assets/images/team/4.png" alt="image" />
          </div>
          <div className="content text-center">
            <h4 className="name">Robin Price</h4>
            <span className="designation">Consultant</span>
            <ul className="socials-links">
              <li><a href="https://account.jetpayglobal.com"><i className="fa fa-facebook-f" /></a></li>
              <li><a href="https://account.jetpayglobal.com"><i className="fa fa-twitter" /></a></li>
              <li><a href="https://account.jetpayglobal.com"><i className="fa fa-google-plus" /></a></li>
              <li><a href="https://account.jetpayglobal.com"><i className="fa fa-instagram" /></a></li>
            </ul>
          </div>
        </div>{/* team-item end */}
        <div className="team-item">
          <div className="thumb">
            <img src="assets/images/team/1.png" alt="image" />
          </div>
          <div className="content text-center">
            <h4 className="name">al burns</h4>
            <span className="designation">ceo</span>
            <ul className="socials-links">
              <li><a href="https://account.jetpayglobal.com"><i className="fa fa-facebook-f" /></a></li>
              <li><a href="https://account.jetpayglobal.com"><i className="fa fa-twitter" /></a></li>
              <li><a href="https://account.jetpayglobal.com"><i className="fa fa-google-plus" /></a></li>
              <li><a href="https://account.jetpayglobal.com"><i className="fa fa-instagram" /></a></li>
            </ul>
          </div>
        </div>{/* team-item end */}
      </div>
    </div>
  </section>
  {/* team-section end */}
  {/* partner-section start */}
  <section className="partner-section pt-120 pb-120">
    <div className="container">
      <div className="row justify-content-between">
        <div className="col-lg-5">
          <div className="partner-wrapper">
            <div className="partner-single wow zoomIn" data-wow-duration="0.3s" data-wow-delay="0.5s">
              <div className="partner-single-inner">
                <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1643286877/coinbase-new4201.jpg" alt="image" />
              </div>
            </div>{/* partner-single end */}
            <div className="partner-single wow zoomIn" data-wow-duration="0.5s" data-wow-delay="0.5s">
              <div className="partner-single-inner">
                <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1643286848/binance-logo-1.png" alt="image" />
              </div>
            </div>{/* partner-single end */}
            <div className="partner-single wow zoomIn" data-wow-duration="0.7s" data-wow-delay="0.5s">
              <div className="partner-single-inner">
                <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1643287041/coingecko-logo-vector.png" alt="image" />
              </div>
            </div>{/* partner-single end */}
            <div className="partner-single wow zoomIn" data-wow-duration="0.9s" data-wow-delay="0.5s">
              <div className="partner-single-inner">
                <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1643286899/vertical_blue.png" alt="image" />
              </div>
            </div>{/* partner-single end */}
          </div>
        </div>
        <div className="col-lg-6">
          <div className="section-header text-lg-left text-center">
            <span className="section-subtitle">Let’s see our</span>
            <h2 className="section-title">Trusted Partners</h2>
            <p>We’re committed to making our clients successful by becoming their partners and trusted advisors .FX-Global believes in being your trusted partner and earning that trust through confidence and performance in service and support.</p>
            <a href="https://account.jetpayglobal.com" className="btn btn-primary btn-hover text-small">join with us</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

  )
}

export default About
