import React, {useEffect, useState, Fragment}  from 'react'
import apiClient from '../../request/http-common';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';



const Profit = () => {
  const [plans, setPlans] = useState([]);
  const getAmount = (amount) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);
  const [value, setValue] = useState([0, 50]);
  const [currentPlan, setCurrentPlan] = useState(null);

  useEffect(() => {
    apiClient.get('/api/investment-plans')
        .then((response) => {
          if(response.data.status === 'success'){
             setPlans(response.data.plans);        
          }
        });
  }, []);

  return (
   <section className="calculate-profit-section pt-120 pb-120">   
  
  {plans.length > 0 &&
  <Fragment>
  <div className="bg_img" style={{backgroundImage: 'url(https://res.cloudinary.com/dzxyvdq14/image/upload/v1693574790/ogffeerboslinc.png)'}} />
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-lg-8">
        <div className="section-header text-center text-white">
          <span className="section-subtitle">Calculate the amazing profits</span>
          <h2 className="section-title">You Can Earn</h2>
          <p>Find out what the returns on your current investments will be valued at, in future. All our issuers have obligation to pay dividends for first year regardless their financial situation that your investments are 100% secured. Calculate your profit from a share using our calculator:</p>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-12">
        <div className="calculate-area wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.5s">
          <ul className="nav nav-tabs justify-content-around" id="calculatorTab" role="tablist">
            {plans.map(plan => (
              <li key={plan.id}>
              <div className="icon"><img src="assets/images/icons/invest-calculate/1.svg" alt="icon-image" /></div>
              <h5 className="package-name">{plan.name}</h5>
              <span className="percentage">{plan.total_earning}%</span>
              <a onClick={()=>setCurrentPlan(plan)} className="active" id="basic-tab" data-toggle="tab" href="#basic" role="tab" aria-controls="basic" aria-selected="true">calculate</a>
            </li>
            ))}
          </ul>
            
            {currentPlan !== null &&
            <div className="tab-content" id="calculatorTabContent">
            <div className="tab-pane fade show active" id="basic" role="tabpanel" aria-labelledby="basic-tab">
              <div className="invest-amount-area text-center">
                <h4 className="title">Amount Invested</h4>
                <div className="main-amount">
                  <input type="text" value={value[1]} className="calculator-invest" id="basic-amount" readOnly />
                </div>

                <RangeSlider
                  min={currentPlan.min_amount}
                  max={currentPlan.amount_invested}
                  id="range-slider-yellow"
                  defaultValue={[0, currentPlan.amount_invested]}
                  onInput={setValue}
                  className="single-thumb"
                  thumbsDisabled={[true, false]}
                  rangeSlideDisabled={true}
                />

              </div>{/* invest-amount-area end */}

              <div className="plan-amount-calculate">
                <div className="item">
                  <span className="caption">{currentPlan.name}</span>
                  <span className="details">Minimum Deposit {getAmount(currentPlan.min_amount)}</span>
                </div>
                <div className="item">
                  <span className="profit-amount">{parseInt(((currentPlan.total_earning/100)* value[1])+ value[1])}</span>
                  <span className="profit-details">Amount To Earn</span>
                </div>
                <div className="item">
                  <span className="profit-amount">{currentPlan.total_earning}%</span>
                  <span className="profit-details">Total Profit</span>
                </div>
                <div className="item">
                  <a href="https://dashboard.jetpayglobal.com/" className="invest-btn btn-round">invest now</a>
                </div>
              </div>


            </div>

          </div>
            }
          


        </div>
      </div>
    </div>
  </div>
  </Fragment>
   }
</section>


  )
}

export default Profit
